window.openShareDialog = function (url, w, h) {
    window.open(url + encodeURIComponent(location.href), 'ShareDialog', 'width=' + w + ',height=' + h);
    return false;
};

window.CopyLink = (e) => {
    let dummy = document.createElement('input');
    document.body.appendChild(dummy);
    dummy.value = window.location.href;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);

    $('#copy-alert').fadeIn();

    setTimeout(hideCopyAlert, 3000);
};

$(function () {
    $('#copy-alert a').on('click', (e) => {
        hideCopyAlert();
    });

/*
    window.fixLiftBlockMargins();
    window.fixContentLiftBlockMargins();

    $(window).resize(window.fixLiftBlockMargins, window.fixContentLiftBlockMargins);

    // This fixes liftblocks which content comes from sst's scripts
    setInterval(function () {
        window.fixLiftBlockMargins();
    }, 500);*/

    var hideCopyAlert = () => {
        $('#copy-alert').fadeOut();
    };
});

/*
window.fixLiftBlockMargins = () => {
    $('.liftrow:not(.content-lift) .liftblock-bg').each((i, e) => {
        fixLiftBlock(e);
    });

    function fixLiftBlock(block) {
        var liftblockHeight = $(block).innerHeight();
        var liftblockContentHeight = $(block).children('.liftblock-content').innerHeight();

        if ($(window).width() > 1199) {
            $(block).css('margin-bottom', liftblockContentHeight - liftblockHeight + 60);
        }
        else if ($(window).width() > 991) {
            $(block).css('margin-bottom', liftblockContentHeight - liftblockHeight + 90);
        }
        else {
            $(block).css('margin-bottom', liftblockContentHeight - 100);
        }
    }
};

window.fixContentLiftBlockMargins = () => {
    $('.content-lift .liftblock-bg').each((i, e) => {
        fixLiftBlock(e);
    });

    function fixLiftBlock(block) {
        var liftblockHeight = $(block).innerHeight();
        var liftblockContentHeight = $(block).children('.liftblock-content').innerHeight();

        if ($(window).width() > 1199) {
            $(block).css('margin-bottom', liftblockContentHeight - 64);
        }
        else if ($(window).width() > 991) {
            $(block).css('margin-bottom', liftblockContentHeight - 48);
        }
        else {
            $(block).css('margin-bottom', liftblockContentHeight - 40);
        }
    }
};
*/

window.initAutocomplete = function (element) {
    element.autocomplete({
        source: function (request, response) {
            $.ajax({
                url: "/find_v2/_autocomplete?prefix=" + encodeURIComponent(request.term) + "&size=5",
                success: function (data) {
                    response($.map(data.hits, function (item) {
                        return {
                            label: item.query,
                            value: item.query
                        };
                    }));
                }
            });
        },
        classes: {
            'ui-autocomplete': 'autocomplete-' + element.attr('id')
        },
        minLength: 2,
        select: function (e, ui) {
            element.val(ui.item.value).parent().submit();
        }
    });
};

window.findNearestPharmacy = (query) => {
    let url = 'https://www.google.com/maps/search/apteekit';

    if (query)
        url += '+in+' + query.replace(' ', '+');

    window.open(url, '_blank');
    //} else {
    //    if (navigator.geolocation) {
    //        navigator.geolocation.getCurrentPosition(function (position) {
    //            url = `${url}/@${position.coords.latitude},${position.coords.longitude}z`;
    //            window.open(url, '_blank');
    //        })
    //    } else {
    //        alert('Selaimesi ei tue geolokaatiota.');
    //    }
    //}
};

function hasParentWithMatchingSelector(target, selector) {
    return [...document.querySelectorAll(selector)].some(el =>
        el !== target && el.contains(target)
    );
}

document.addEventListener("DOMContentLoaded",
    function() {
        (function(targetId) {

                var headingtags = ["h2"];
                var all_el = document.getElementsByClassName("h2-anchor");
                var target = document.getElementById(targetId);
               

                var headings = all_el;
                //console.log(headings);
                if (headings.length >= 1) {
                    var menuList = document.createElement("ul");
                    for (var i = 0; i < headings.length; i++) {
                        var anchorName = "";
                        if (headings[i].id) {
                            anchorName = headings[i].id;
                        } else {
                            anchorName = "section_" + i;
                            headings[i].id = anchorName;
                        }
                        //add svg and link

                        var a = document.createElement('a');
                        a.href = "#print-header";
                        
                        var iTag = document.createElement("i");
                        iTag.setAttribute('class','fas fa-angle-up');


                        a.appendChild(iTag);

                        headings[i].appendChild(a);

                        var headingTag = headings[i].nodeName.replace("H", "");;
                        var level = "";
                        var levelcount = 0;
                        for (var a = 0; a < headingTag - 2; a++) {
                            level = level + "- ";
                            levelcount = levelcount + 1;
                        }
                        var headingText = level + " " + headings[i].innerText;

                        var menuLink = document.createElement("a");
                        menuLink.setAttribute("href", "#" + anchorName);
                        menuLink.appendChild(document.createTextNode(headingText));

                        var menuLI = document.createElement("li");
                        menuLI.appendChild(menuLink);
                        menuList.appendChild(menuLI);
                    }
                    while (target.hasChildNodes()) target.removeChild(target.firstChild);
                    target.appendChild(menuList);
                } else {
                    //document.getElementById("dropdown").style.display = "none";
                }
            })
            ("submenu");
    });

$(document).ready(function () {

    /*
    $('#submenu > ul > li > a').on('click', function(e) {
        e.preventDefault();
        $("html, body").animate({ scrollTop: $($(this).attr("href")).offset().top - 120 }, 500);
    });
    */
    /*
    $('.h2-anchor > a').on('click', function(e) {
        e.preventDefault();
        //$("html, body").animate({ scrollTop: 0 }, 500);
        $("html, body").animate({ scrollTop: $("#submenu").offset().top - 180 }, 500);
    });
    */

    $(document).on('click', 'a[href^="#"]', function (event) {
        event.preventDefault();

        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top - 180
        }, 500);
    });
    

});

//Hide main menu on mobile when scrolling down
var prevScrollpos = window.pageYOffset;
var brandnav = document.getElementById("brandnav");

window.onscroll = function () {
    if (window.outerWidth < 992) {
        var currentScrollPos = window.pageYOffset;
        if (prevScrollpos > currentScrollPos) {
            document.getElementById("orion-navbar").style.top = "0";
            if (typeof (brandnav) != 'undefined' && brandnav != null) {
                document.getElementById("brand-nav").style.top = "78px";
            }

            
        } else {
            document.getElementById("orion-navbar").style.top = "-78px";
            if (typeof (brandnav) != 'undefined' && brandnav != null) {
                document.getElementById("brand-nav").style.top = "0";
            }
            
        }
        prevScrollpos = currentScrollPos;
    } else {
        document.getElementById("orion-navbar").style.top = "0";
        if (typeof (brandnav) != 'undefined' && brandnav != null) {
            document.getElementById("brand-nav").style.top = "90px";
        }
        
    }

}